import React, {useState} from 'react';
import Envelope from './components/envelope/Envelope';
import styled from 'styled-components';
import LightSwitch from './components/LightSwitch';
import Grid from '@material-ui/core/Grid';

function App() {
  const [isOn, setIsOn] = useState(true)

  const text = 
  <span>
    <h2>Hurra!</h2>
    <p>Nå har du et eget domene (elisavalenti.no). Kult ? Kanskje ikke. Null stress om du ikke synes det, jeg skaffet 
    det utelukkende for å gi deg et digitalt brev.
    <br/><br/>
    
    Jeg håper du har hatt et godt semester hjemme, at mamma har vært grei mot deg og ikke minst at du har vært 
    god mot deg selv. Vit at så lenge jeg kan holde en datamus så vil jeg være din <b>IT person</b>. 
    Glad i deg!
    <br/>
    <br/>
    <i>Hilsen lillesøster</i></p>
    
  </span>

  return (
    <PageWrapper>
    <GridContainer container isOn={isOn}>
      <Grid item sm={2}></Grid>
      <Grid item sm={8}> 
        <Text isOn={isOn}>
          <p>Nice! Sjekk brevet på nytt.</p>
        </Text> 
      </Grid>
      <Grid item sm={1}>
        <span onClick={() => setIsOn(prev => !prev)} ><LightSwitch isOn={isOn}></LightSwitch></span>
      </Grid>
      <Grid item sm={1}></Grid>
      <Grid item sm={2}></Grid>
      <Grid item sm={8}>
        <Tittel>{isOn? "Ciao Elisa!" : "Se igjen!"}</Tittel>
        <Envelope text={isOn? <p><h2>Tanti Auguri!</h2> Spegni la luce che a programmatori da fastidio</p>: text} />
      </Grid>
      <Grid item sm={2}></Grid>
    </GridContainer>
    </PageWrapper>
  );
}

export default App;

const PageWrapper = styled.div`
  height: 100vh;
`
const GridContainer = styled(Grid)`
  color: ${props => props.isOn? "#161616" : "white"};
  margin: 0;
  overflow: hidden;
  height: 100%;
  transition: 0.2s;
  background-color: ${props => props.isOn? "white" : "#161616"};
  z-index: -2;
`;

const Tittel = styled.h1`
  display: flex;
  justify-content: center;
  user-select: none;
`

const Text = styled.p`
  text-align: center;
  font-size: 2em;
  visibility: ${props => props.isOn?  "hidden" : "visible"};
`