import styled from 'styled-components';
import { useState } from 'react';

export default function Envelope({ text }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
     
      <div>
        <Wrapper onMouseOver={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}>
          <BackPane />
          <Letter isOpen={isOpen}>{text}</Letter>
          <Shell>
            <path
              id="sideFlaps"
              d="M0,0V311l121.48-90.73c3.38-2.52,5.52-7.74,5.52-13.46V56c0-7-3.19-13.07-7.68-14.63Z"
            />
            <path
              id="sideFlaps"
              d="M544,0V311L422.52,220.27c-3.38-2.52-5.52-7.74-5.52-13.46V56c0-7,3.19-13.07,7.68-14.63Z"
            />
            <path
              className="shadow"
              d="M88.63,133.69.33,311h544L456,133.69a35.73,35.73,0,0,0-31.62-19.12H120.26A35.73,35.73,0,0,0,88.63,133.69Z"
            />
            <path
              className="bottomFlap"
              d="M88.3,142.69,0,311H544L455.7,142.69a35.73,35.73,0,0,0-31.63-19.12H119.93A35.73,35.73,0,0,0,88.3,142.69Z"
            />
          </Shell>
          <Flap isOpen={isOpen}>
            <path d="M417.56,162H126.44A35.3,35.3,0,0,1,97,146.22L0,0H544L447,146.22A35.3,35.3,0,0,1,417.56,162Z" />
          </Flap>
        </Wrapper>
      </div>
  );
}

const BackPane = styled.div`
  box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.1);
  z-index: 1;
  width: 545px;
  height: 311px;
  position: absolute;
  background-color: #d8c491;
`;

const Letter = styled.div`
  box-shadow: 2px 7px 6px 6px rgba(0,0,0,0.03);

  // Alignments
  z-index: 3;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 0 25px 0 25px;

  width: 460px;
  height: 300px;
  position: absolute;

  color: #868686;
  white-space: pre-line;
  text-align: center;
  background-color: #e7e7e7;
  font-size: 17px;

  transition: ${(props) => (props.isOpen ? '0.4s' : '0.5s')};
  transition-delay: ${(props) => (props.isOpen ? '0.3s' : '0s')};
  transform: ${(props) =>
    props.isOpen ? 'translate(0px, -300px)' : 'translate(0px, 0px)'};
`;

const Shell = styled.svg`
  & .bottomFlap {
    height: 187px;
    fill: #d8c491;
  }
  & #sideFlaps {
    fill: #c9b17d;
  }
  & .shadow {
    fill: rgba(183, 157, 105, 0.603);
  }
  z-index: 4;
  width: 545px;
  height: 311px;
  position: absolute;
`;

const Flap = styled.svg`
  & path {
    fill: ${(props) => (props.isOpen ? '#EAD8AD' : '#EAD8AD')};
  }
  z-index: ${(props) => (props.isOpen ? 2 : 5)};

  width: 545px;
  height: 162px;

  transform-origin: 0% 0%;
  transition: ${(props) => (props.isOpen ? '0.5s' : '0.7s')};
  transition-delay: ${(props) => (props.isOpen ? '0s' : '0.3s')};
  transform: ${(props) =>
    props.isOpen
      ? 'translate(0px, 0px) rotateX(180deg)'
      : 'translate(0px, 0px) rotateX(0deg)'};
`;

const Wrapper = styled.div`
  isolation: isolate;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;
