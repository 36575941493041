import styled from 'styled-components';

function LightSwitch(props) {
  return (
      <Switch>
        <path
          d="M61.64,331.5A30.82,30.82,0,1,1,25.1,301.21V0H36.54V301.21A30.82,30.82,0,0,1,61.64,331.5Z"
        />
      </Switch>
  );
}

export default LightSwitch;

const Switch = styled.svg`
  height: 363px;
  width: 62px;
  position: relative;
`;

